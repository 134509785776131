let resumeData = {
  imagebaseurl: 'https://',
  name: 'Kevin Laws',
  role: 'Software Engineer',
  linkedinId: 'kevin-laws-82748757',
  skypeid: 'Your skypeid',
  roleDescription:
    'I am experienced with React, Web Design, C#, .NET MVC, Bootstrap, Javascript, SQL and all stages of development. Strong background in UI/UX and front end development.',
  socialLinks: [
    {
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/kevin-laws-82748757/',
      className: 'fab fa-linkedin'
    },
    {
      name: 'github',
      url: 'https://github.com/T0wNH3r0',
      className: 'fab fa-github'
    },
    {
      name: 'skype',
      url: 'https://twitter.com/KevinLawsDev',
      className: 'fab fa-twitter'
    }
  ],
  aboutme:
    'I am software engineer with a passion for design.',
  email: 'info@kevin-laws.com',
  address: 'Roanoke, Virginia',
  website: 'kevin-laws.com',
  education: [
    {
      UniversityName: 'Virginia Western',
      specialization: 'Web Programming',
      MonthOfPassing: 'Aug',
      YearOfPassing: '2013',
      Achievements: 'Some Achievements'
    },
    {
      UniversityName: 'Virginia Western',
      specialization: 'Database & Program Development',
      MonthOfPassing: 'Jan',
      YearOfPassing: '2015',
      Achievements: 'Some Achievements'
    }
  ],
  work: [
    {
      CompanyName: 'OneBeacon',
      specialization: 'Programmer Analyst',
      MonthOfLeaving: '-',
      YearOfLeaving: '-',
      Achievements: 'Some Achievements'
    },
    {
      CompanyName: 'Ozmo',
      specialization: 'Software Engineer',
      MonthOfLeaving: 'February',
      YearOfLeaving: '2019',
      Achievements: 'Some Achievements'
    },
    {
      CompanyName: 'City of Roanoke',
      specialization: 'Application Developer',
      MonthOfLeaving: 'August',
      YearOfLeaving: '2018',
      Achievements: 'Some Achievements'
    }
  ],
  skillsDescription: 'Your skills here',
  skills: [
    {
      skillname: 'HTML5'
    },
    {
      skillname: 'CSS'
    },
    {
      skillname: 'React'
    },
    {
      skillname: 'Javascript'
    },
    {
      skillname: 'C# MVC'
    },
    {
      skillname: 'SQL'
    }
  ],
  portfolio: [
    {
      name: "Setta's Place",
      description: 'online store',
      imgUrl: 'images/portfolio/origami.jpg',
      imgTitle: '',
      alt: '',
      url: 'https://settasplace.com/'
    },
    {
      name: '37th Annual Parlor Tour',
      description: 'First SmartHome to appear on this annual parlor tour.',
      imgUrl: 'images/portfolio/console.jpg',
      imgTitle: '',
      alt: '',
      url:
        'https://www.virginiafirst.com/news/local-news/37th-old-southwest-parlor-tour-opens-this-weekend/614647779'
    }
  ],
  recommendations: [
    {
      name: 'Smart Hub',
      description: 'Samsung SmartThings',
      imgurl: 'home',
      url: ''
    },
    {
      name: 'Door & Window Sensors',
      description: 'Ecolink',
      imgurl: 'exchange-alt',
      url: ''
    },
    {
      name: 'Motion Detection',
      description: 'Ecolink',
      imgurl: 'sign-language',
      url: ''
    },
    {
      name: 'Lighting',
      description: 'Philips Hue',
      imgurl: 'lightbulb',
      url: ''
    },
    {
      name: 'Switches',
      description: 'Wemo',
      imgurl: 'plug',
      url: ''
    },
    {
      name: 'Smart Router',
      description: 'Google Wifi',
      imgurl: 'wifi',
      url: ''
    },
    {
      name: 'Voice Assistant',
      description: 'Google Home',
      imgurl: 'microphone',
      url: ''
    },
    {
      name: 'Voice Assistant',
      description: 'Alexa',
      imgurl: 'microphone',
      url: ''
    },
    {
      name: 'Thermostat',
      description: 'Ecobee',
      imgurl: 'thermometer-half',
      url: ''
    },
    {
      name: 'Smart Vents',
      description: 'Keen Home',
      imgurl: 'wind',
      url: ''
    },
    {
      name: 'Smart Shades',
      description: 'Bali Blinds',
      imgurl: 'align-justify',
      url: ''
    }
  ],
  testimonials: [
    {
      description: 'This is a sample testimonial',
      name: 'Some technical guy'
    },
    {
      description: 'This is a sample testimonial',
      name: 'Some technical guy'
    }
  ]
};

export default resumeData;
